<template>
  <b-card title="Admin Dashboard" class="m-2">
    <b-card>
      <b-card-text>Export all user data as a CSV file.</b-card-text>
      <b-button
        @click="exportUsers('usersAll.csv')"
        variant="primary"
        class="mb-2"
        >Export All
      </b-button>
      <hr />
      <b-card-text>Export opted-in user data as a CSV file.</b-card-text>
      <b-button
        @click="exportUsers('usersOptIn.csv', { receiveMessages: true })"
        variant="primary"
        class="mb-2"
        >Export Opt-In
      </b-button>
    </b-card>
  </b-card>
</template>

<script>
import API from "@/api";

export default {
  name: "Admin",
  data() {
    return {};
  },
  methods: {
    async exportUsers(filename, filter = {}) {
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("users");

      const results = await mongoCollection.find(filter);

      if (results.length == 0) return;

      // Create CSV

      const items = results;

      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);

      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );

      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      console.log(csv);

      // Save CSV

      const dataURL = "data:text/csv;charset=utf-8," + encodeURI(csv);

      const anchor = document.createElement("a");
      anchor.href = dataURL;
      anchor.download = filename;
      anchor.click();
    },
  },
};
</script>